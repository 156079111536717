<template>
  <div>
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <v-layout column>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form @submit.prevent="submit">
          <v-layout column class="border-primary rounded-lg px-8 pt-4">
            <v-layout>
              <v-layout column col-4 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("shop_name") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="textRequired"
                  vid="name_shop"
                  :rules="requiredRules"
                >
                  <v-text-field
                    v-model="shopName"
                    outlined
                    dense
                    class="rounded-lg"
                    :maxlength="maximumSmallText"
                    :error-messages="errors"
                    :placeholder="$t('place_holders.shop_name')"
                    @change="shopName = trimSpace(shopName)"
                  />
                </validation-provider>
              </v-layout>
              <v-layout column col-4 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("phone") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="phone"
                  :rules="phoneRules"
                >
                  <v-text-field
                    v-model="phone"
                    outlined
                    dense
                    class="rounded-lg"
                    :maxlength="maximumPhone"
                    :error-messages="errors"
                    :placeholder="$t('place_holders.phone')"
                    @keypress="isNumber($event)"
                  />
                </validation-provider>
              </v-layout>
              <v-layout column col-4 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("email") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  :rules="emailRules"
                >
                  <v-text-field
                    v-model="email"
                    outlined
                    dense
                    class="rounded-lg"
                    :maxlength="maximumEmail"
                    :error-messages="errors"
                    :placeholder="$t('place_holders.email')"
                  />
                </validation-provider>
              </v-layout>
            </v-layout>
            <v-layout>
              <v-layout column col-4 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ `${$t("address")} ${$t("shop")}` }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="province"
                  :rules="requiredRules"
                >
                  <v-autocomplete
                    v-model="provinceSelected"
                    clearable
                    outlined
                    dense
                    class="rounded-lg"
                    item-text="name"
                    item-value="code"
                    hide-no-data
                    :placeholder="$t('place_holders.province')"
                    :items="lstProvince"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-layout>
              <!--san luong don-->
              <v-layout column col-4 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("order_quantity") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="orderQuantity"
                  :rules="requiredRules"
                >
                  <v-text-field
                    v-model="orderQuantity"
                    outlined
                    dense
                    class="rounded-lg"
                    :maxlength="maximumPhone"
                    :error-messages="errors"
                    :placeholder="$t('place_holders.order_quantity')"
                    @keypress="isNumber($event)"
                  />
                </validation-provider>
              </v-layout>
              <!--ma gioi thieu-->
              <v-layout column col-4 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("referral_code") }}
                </label>
                <v-text-field
                  v-model="referralCode"
                  outlined
                  dense
                  class="rounded-lg"
                  :maxlength="maximumSmallText"
                  :placeholder="$t('place_holders.referral_code')"
                  @change="referralCode = trimSpace(referralCode)"
                />
              </v-layout>
            </v-layout>
          </v-layout>
          <v-layout justify-end>
            <v-btn
              color="primary"
              type="submit"
              class="rounded-lg mt-4"
              :disabled="invalid"
            >
              {{ $t("create_customer") }}
            </v-btn>
          </v-layout>
        </v-form>
      </validation-observer>
      <!-- Dialog loading -->
      <dialog-loading v-model="showLoading" :message="$t('processing')" />

      <!-- notifications -->
      <dialog-notification
        v-model="showNoti"
        :persistent="persistent"
        :icon-src="getIcon"
        :message="message"
      />
    </v-layout>
  </div>
</template>

<script>
import Breadscrumbs from "@/components/base/Breadscrumbs";
import DialogNotification from "@/components/dialog/DialogNotification.vue";
import DialogLoading from "@/components/dialog/DialogLoading.vue";
import routePaths from "@/router/routePaths";

import { StorageService } from "@/services/storageService";
import { FormUtils } from "@/helpers/formUtils";
import { UserService } from "@/services/userService";
import { StringUtils } from '@/helpers/stringUtils'

import constants from "@/constants";

export default {
  components: {
    Breadscrumbs,
    DialogLoading,
    DialogNotification,
  },
  data() {
    return {
      constants,
      breadcrumbs: [
        {
          text: "list_customers",
          disabled: false,
          isActive: false,
          href: routePaths.CUSTOMERS
        },
        {
          text: "create_customer",
          disabled: true,
          isActive: true,
        },
      ],
      requiredRules: {
        required: true,
      },
      shopName: '',
      phone: "",
      phoneRules: {
        min: constants.minimumPhone,
        max: constants.maximumPhone,
        regex: constants.regExp.phone,
        required: true,
      },
      email: "",
      emailRules: {
        required: true,
        email: true,
        min: constants.minimumEmail,
        max: constants.maximumEmail,
        regex: constants.regExp.email,
      },
      orderQuantity: '',
      referralCode: '',
      maximumSmallText: constants.maximumSmallText,
      maximumPhone: constants.maximumPhone,
      provinceSelected: null,
      lstProvince: [],
      maximumEmail: constants.maximumEmail,
      showNoti: false,
      persistent: false,
      typeNoti: constants.typeAlert.warning,
      message: "",
      showLoading: false,
      currentRole: JSON.parse(localStorage.getItem("CURRENT_USER")).roles,
    }
  },
  computed: {
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti)
    },
  },
  created() {
    if (window.location.pathname && (!this.currentRole.includes("ROLE_ADMIN"))) {
      this.toggleDialogNoti({
        state: true,
        msg: this.$t("no-mission-msg"),
      });
      setTimeout(() => {
        this.$router.go(-1);
      }, constants.timeOutRole);
    }
  },
  beforeMount() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getProvinces();
    },
    async getProvinces() {
      this.lstProvince = await StorageService.getProvinces();
    },
    async submit() {
      var valid = this.$refs.observer.validate();
      if (valid) {
        this.showLoading = true;
        const pars = this.bindRequestPars();
        const { status, data } = await UserService.createShop(pars);
        this.showLoading = false;
        if (status === constants.statusCode.ok) {
          this.toggleDialogNoti({
            state: true,
            persistent: true,
            type: constants.typeAlert.success,
            msg: this.$t("create_shop_success"),
          });
          setTimeout(() => {
            this.$router.push(routePaths.CUSTOMERS_ACTIVATION_WAITING);
          }, constants.timeOut);
        } else {
          this.toggleDialogNoti({ state: true, msg: data.message });
          setTimeout(() => {
            this.toggleDialogNoti();
          }, constants.timeOut);
        }
      }
    },
    bindRequestPars() {
      const pars = {
        name: this.shopName.trim(),
        phone: this.phone.trim(),
        email: this.email,
        provinceCode: this.provinceSelected,
        quantityPerDay: parseInt(this.orderQuantity),
        referCode: this.referralCode,
        acceptedPolicy: true
      }
      return pars
    },
    toggleDialogNoti(
      pars = {
        state: false,
        persistent: false,
        type: constants.typeAlert.warning,
        msg: "",
      }
    ) {
      this.showNoti = pars.state;
      this.persistent = pars.persistent;
      this.typeNoti = pars.type;
      this.message = pars.msg;
    },
    trimSpace(val) {
      return val ? val.trim() : "";
    },
    isNumber(evt) {
      return FormUtils.isNumber(evt);
    },

  },
}
</script>

<style src="@/styles/createMultiOrder.scss" lang="scss">
</style>
